import { RxOpenInNewWindow } from "react-icons/rx";
import { useTranslation } from 'react-i18next';

const ProjectCard = ({ project }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.languages[0];
    return (
        <div className="card project-card h-100">
            <a className="text-decoration-none" href={project.link} target="blank">
                <div className="card-img-top">
                    <img src={project.image} alt={project.name} />
                </div>
                <div className="card-body">
                    <h5 className="card-title"><strong>{project.name}</strong></h5>
                    <div className="d-inline-flex gap-1 mb-2">
                        {project.tags.map(tag => <span className="badge" key={tag}>{tag}</span>)}
                    </div>
                    <p className="card-text">{project.description[currentLanguage]}</p>
                </div>
            </a>
        </div>
    );
};

export default ProjectCard;