import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import ProjectCard from "./components/ProjectCard";
import projects from "./data/projects.json";
import emailjs from "@emailjs/browser";
import { Link } from "react-scroll";
import Skill from "./components/Skill";
import ServiceCard from "./components/ServiceCard";
import { IoBarChartOutline } from "react-icons/io5";
import { LiaLaptopCodeSolid } from "react-icons/lia";
import './i18n'; // Import the i18n configuration
import { useTranslation } from 'react-i18next';

const skills = [
    ["Python", "devicon-python-plain"],
    ["JavaScript", "devicon-javascript-plain"],
    ["React", "devicon-react-original"],
    ["Node", "devicon-nodejs-plain"],
    ["Flask", "devicon-flask-original"],
    ["Tensorflow", "devicon-tensorflow-original"],
    ["Dart", "devicon-dart-plain"],
    ["Flutter", "devicon-flutter-plain"],
    ["Linux", "devicon-linux-plain"],
];

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 20;
        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        }
    }
}

window.addEventListener("scroll", reveal);

reveal();

function App() {
    const [formState, setFormState] = useState({
        from_name: "",
        reply_to: "",
        message: "",
    });

    const [projectList, setProjectList] = useState(projects);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidMessage, setIsValidMessage] = useState(true);
    const [isValidName, setIsValidName] = useState(true);
    const [messageSent, setMessageSent] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (i18n.isInitialized) {
            setIsLoaded(true);
        }
    }, [i18n.isInitialized]);

    const allTags = [
        t('categories.all'),
        ...new Set(projects.map((project) => t(`categories.${project.category}`)).flat()),
    ];

    const inputChangeHandler = (event) => {
        setMessageSent(false);
        if (event.target.name === "from_name") setIsValidName(true);
        if (event.target.name === "reply_to") setIsValidEmail(true);
        if (event.target.name === "message") setIsValidMessage(true);
        setFormState({
            ...formState,
            [event.target.name]: event.target.value,
        });
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
        try {
            let isValid = true;
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.reply_to)) {
                isValid = false;
                setIsValidEmail(false);
            }

            if (formState.message.length === 0) {
                isValid = false;
                setIsValidMessage(false);
            }

            if (formState.from_name.length === 0) {
                isValid = false;
                setIsValidName(false);
            }

            if (isValid && !isWaiting) {
                // function sleep() {
                //   return new Promise(resolve => setTimeout(resolve, 3000));
                // }
                setIsWaiting(true);
                setMessageSent(false);
                // await sleep();
                await emailjs.send(serviceId, templateId, formState, publicKey);
                setIsWaiting(false);
                setMessageSent(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const filterProjects = (category) => {
        setSelectedCategory(category);
        if (category === t('categories.all')) {
            setProjectList(projects);
        } else {
            const filteredProjects = projects.filter(
                (project) => t(`categories.${project.category}`) === category
            );
            setProjectList(filteredProjects);
        }
    };
    

    return (
        <div className="container p-4">
            <Navbar home={t('menu_home')} about={t('menu_about')} projects={t('menu_projects')} contact={t('menu_contact')} />
            <section id="#Home">
                <div className="row hero">
                    <div className="d-flex flex-column text-center" style={{ height: "90vh" }}>

                        <div className={`appear mt-auto ${isLoaded ? "" : "invisible"}`}>
                            <h1 className="display-1 title">
                                {t('welcome_message')}
                                <span className="title-bold">
                                    Jonas
                                </span>
                            </h1>

                            <div className="lead mb-4">
                                {t('welcome_message_description')}
                            </div>
                            <div className="mt-4 d-flex flex-wrap gap-2 justify-content-center">
                                <Link
                                    to="#About"
                                    className="btn btn-outline-primary py-2 px-4 my-button"
                                    offset={-100}
                                >
                                    {t('discover_my_work')}
                                </Link>
                                <Link
                                    to="#Contact"
                                    className="btn btn-primary py-2 px-4 my-button"
                                    offset={-100}
                                >
                                    {t('start_a_conversation')}
                                </Link>
                            </div>
                        </div>
                        <div className="d-flex mx-auto mt-auto mb-4 gap-2">
                            <a
                                href="https://github.com/jgregoriods"
                                target="blank"
                                className="icon-link"
                            >
                                <i
                                    className="devicon-github-original"
                                    style={{ fontSize: 40 }}
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/jgregoriods/"
                                target="blank"
                                className="icon-link"
                            >
                                <i
                                    className="devicon-linkedin-plain"
                                    style={{ fontSize: 40 }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="#About">
                <div className="">
                    <h1
                        className="h1 session-title text-center"
                        style={{ marginTop: "80px" }}
                        id="about"
                    >
                        {t('about_me_title')}
                    </h1>
                    <h4 className="h5 mb-4 text-center">
                        {t('about_me_subtitle')}
                    </h4>
                    <div className="text-center mb-4 col-4 mx-auto divider"></div>

                    {/* <hr className='divider mx-auto'></hr> */}
                    <div className="row">
                        {/* <div className="col-lg-4 text-center my-auto">
                            <img
                                src="img/jonas.png"
                                style={{
                                    filter: "saturate(0.8)",
                                }}
                                height="300"
                                className="reveal"
                                alt="Jonas Gregorio"
                            />
                        </div> */}
                        {/* <div className="col-lg-8 my-auto"> */}
                            <p className="main-text">
                                {t('about_me_description')}
                            </p>
                        {/* </div> */}
                    </div>
                </div>
                <div className="col" style={{ marginBottom: 80 }}>
                    <h1
                        className="h1 text-center session-title"
                        style={{ marginTop: 80 }}
                    >
                        {t('skills_title')}
                    </h1>
                    <h4 className="h5 mb-4 text-center">
                        {t('skills_subtitle')}
                    </h4>
                    <div className="text-center mb-4 col-4 mx-auto divider"></div>
                    <div className="d-flex flex-wrap mt-4 justify-content-center reveal">
                        {skills.map((skill) => {
                            return <Skill name={skill[0]} icon={skill[1]} />;
                        })}
                    </div>

                    {/* <div className="text-center"><FaCode fontSize={36} style={{ marginTop: 80 }} /></div> */}
                    <h1
                        className="h1 text-center session-title"
                        style={{ marginTop: 80 }}
                    >
                        {t('services_title')}
                    </h1>
                    <h4 className="h5 text-center mb-4">
                        {t('services_subtitle')}
                    </h4>
                    <div className="text-center mb-4 col-4 mx-auto divider"></div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 mt-4 justify-content-center reveal">
                        <div className="col mb-3">
                            <ServiceCard
                                icon={
                                    <LiaLaptopCodeSolid
                                        fontSize={48}
                                        className="skill-icon"
                                    />
                                }
                                title={t('web_development')}
                                text={t('web_development_description')}
                            />
                        </div>
                        <div className="col mb-3">
                            <ServiceCard
                                icon={
                                    <IoBarChartOutline
                                        fontSize={48}
                                        className="skill-icon"
                                    />
                                }
                                title={t('data_analysis')}
                                text={t('data_analysis_description')}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="#Projects">
                <div className="col">
                    {/* <div className="text-center"><FaCode fontSize={36} /></div> */}
                    <h1 className="h1 text-center session-title" id="projects">
                        {t('projects_title')}
                    </h1>
                    <h4 className="h5 mb-4 text-center">
                        {t('projects_subtitle')}
                    </h4>
                    <div className="text-center mb-4 col-4 mx-auto divider"></div>

                    {/* <hr className='divider mx-auto mb-4'></hr> */}

                    <div className="d-flex flex-wrap">
                        {allTags.map((tag) => {
                            return (
                                <span
                                    className={
                                        selectedCategory === tag
                                            ? "chip chip-active"
                                            : "chip"
                                    }
                                    onClick={() => filterProjects(tag)}
                                >
                                    {tag}
                                </span>
                            );
                        })}
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 mt-4 reveal">
                        {projectList.map((project) => {
                            return (
                                <div className="col mb-4" key={project.name}>
                                    <ProjectCard project={project} />
                                </div>
                            );
                        })}
                    </div>

                    <h1
                        className="h1 text-center session-title"
                        id="projects"
                        style={{ marginTop: 80 }}
                    >
                        {t('open_source_title')}
                    </h1>
                    <h4 className="h5 mb-4 text-center">
                        {t('open_source_subtitle')}
                    </h4>
                    <div className="text-center mb-4 col-4 mx-auto divider"></div>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 mt-4 reveal">
                        <div className="col mb-4 mx-auto">
                            <ProjectCard
                                project={{
                                    name: "Wordview",
                                    link: "https://github.com/meghdadFar/wordview",
                                    image: "img/wordview.png",
                                    tags: ["Python"],
                                    description: {
                                        "en": "A Python package created by @meghdadFar for Exploratory Data Analysis of text-based data, such as words, n-grams and POS tags",
                                        "pt": "Um pacote Python criado por @meghdadFar para Análise Exploratória de Dados de texto, como palavras, n-grams e tags POS"
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="#Contact">
                <div>
                    <div className="col">
                        {/* <div className="text-center"><FaCode fontSize={36} style={{ marginTop: 120 }} /></div> */}
                        <h1
                            className="h1 text-center session-title"
                            id="contact"
                            style={{ marginTop: 120 }}
                        >
                            {t('contact_title')}
                        </h1>
                        <h4 className="h5 mb-4 text-center">
                            {t('contact_subtitle')}
                        </h4>
                        <div className="text-center mb-4 col-2 mx-auto divider"></div>

                        {/* <hr className='divider mx-auto'></hr> */}
                        <div className="col-lg-6 mx-auto">
                            <div className="mb-3 mt-4">
                                <input
                                    type="text"
                                    className={`form-control ${isValidName ? "" : "invalid"}`}
                                    placeholder={t('your_name')}
                                    name="from_name"
                                    onChange={inputChangeHandler}
                                ></input>
                                {!isValidName && (
                                    <p className="warning">
                                        {t('enter_your_name')}
                                    </p>
                                )}
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className={`form-control ${isValidEmail ? "" : "invalid"}`}
                                    placeholder={t('your_email')}
                                    name="reply_to"
                                    onChange={inputChangeHandler}
                                ></input>
                                {!isValidEmail && (
                                    <p className="warning">
                                        {t('enter_valid_email')}
                                    </p>
                                )}
                            </div>
                            <div className="mb-3">
                                <textarea
                                    rows="4"
                                    className={`form-control ${isValidMessage ? "" : "invalid"}`}
                                    placeholder={t('your_message')}
                                    name="message"
                                    onChange={inputChangeHandler}
                                ></textarea>
                                {!isValidMessage && (
                                    <p className="warning">
                                        {t('enter_your_message')}
                                    </p>
                                )}
                            </div>

                            <div className="mb-3 mt-4 col-12 mx-auto text-center">
                                <button
                                    className="btn btn-primary px-4 py-2 my-button"
                                    onClick={submitHandler}
                                >
                                    {t('send')}
                                </button>
                            </div>

                            {isWaiting && (
                                <div className="text-center">
                                    <div
                                        className="spinner-border mt-4"
                                        role="status"
                                    >
                                        <span className="visually-hidden">
                                            {t('loading')}
                                        </span>
                                    </div>
                                </div>
                            )}

                            {messageSent && (
                                <p className="text-center mt-4">
                                    {t('thank_you_message')}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <div className="footer" style={{ height: "25vh" }}></div>
        </div>
    );
}

export default App;
