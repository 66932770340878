import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { useState } from 'react';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.languages[0];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      const bsCollapse = new window.bootstrap.Collapse(navbarCollapse);
      bsCollapse.hide();
    }
  };

  const languageLabel = currentLanguage === "pt" ? "PT" : "EN";

  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="languageDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {languageLabel}
      </a>
      <ul className="dropdown-menu" aria-labelledby="languageDropdown">
        <li>
          <button className="dropdown-item" onClick={() => changeLanguage("en")}>
            English
          </button>
        </li>
        <li>
          <button className="dropdown-item" onClick={() => changeLanguage("pt-BR")}>
            Português
          </button>
        </li>
      </ul>
    </li>
  );
};

export default LanguageSwitcher;
