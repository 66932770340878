const Skill = props => {
    return (
        <div className="skill mr-4 p-2">
            <div className="col">
                <div className="row text-center mb-1">
                    <i className={`${props.icon}`} style={{ fontSize: 24 }}></i>
                </div>
                <div className="row text-center">
                    <p className='mb-0' style={{ fontSize: 14 }}>{props.name}</p>
                </div>
            </div>
        </div>
    );
}

export default Skill;