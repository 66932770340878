const ServiceCard = ({ icon, title, text }) => {
  return (
    <div className='card service-card h-100 p-2'>
      <div className='card-body'>
        <div className='d-flex justify-content-center'>
          {/* <img src={WebIcon} alt={icon} /> */}
          {icon}
        </div>
        <h5 className='card-title text-center mt-3'><strong>{title}</strong></h5>
        <p className='card-text'>{text}</p>
      </div>
    </div>
  );
}

export default ServiceCard;